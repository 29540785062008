import { ExitIcon, GearIcon, PersonIcon, QuestionMarkIcon } from "@radix-ui/react-icons";
import { Link, useLocation, useParams } from "@remix-run/react";
import { Avatar, Dropdown, Flex } from "sparta";
import anonPic from "~/assets/anonIcon.svg";
import { useRootLoaderData } from "~/root";
import useResumeablePath from "~/utils/hooks/useResumeablePath";
import { AccountAction } from "./AccountAction";

export default function AccountAvatar() {
  const { user } = useRootLoaderData();
  const location = useLocation();
  const path = useResumeablePath();
  const params = useParams();
  const showAuthButtons = user?.anonymous || !user;

  return (
    <Dropdown>
      <Dropdown.Trigger>
        <Flex gap="1" align="center">
          <Avatar
            size="1"
            fallback={user?.data?.fullName ?? "User"}
            src={showAuthButtons ? anonPic : user?.data?.profilePicture?.url}
          />
          <Dropdown.TriggerIcon />
        </Flex>
      </Dropdown.Trigger>
      <Dropdown.Content variant="soft" sideOffset={8}>
        {showAuthButtons ? (
          <>
            <Dropdown.Item asChild>
              <Link to={`/access/login?resume=${path}${params?.platform ? `&platform=${params.platform}` : ""}`}>
                Log In
              </Link>
            </Dropdown.Item>
            <Dropdown.Item asChild>
              <a href="https://g2xchange.com/pricing">Sign Up</a>
            </Dropdown.Item>
          </>
        ) : (
          <>
            <Dropdown.Item asChild>
              <AccountAction href="/settings/profile" title="Profile" icon={<PersonIcon />} />
            </Dropdown.Item>
            <Dropdown.Item asChild>
              <AccountAction href="/settings/account" title="Settings" icon={<GearIcon />} />
            </Dropdown.Item>
            <Dropdown.Item asChild>
              <AccountAction
                href="https://support.g2xchange.com/general-information"
                title="Help"
                icon={<QuestionMarkIcon />}
              />
            </Dropdown.Item>

            <Dropdown.Separator />
            <Dropdown.Item asChild color="red">
              <AccountAction href="/logout" title="Log Out" icon={<ExitIcon />} />
            </Dropdown.Item>
          </>
        )}
      </Dropdown.Content>
    </Dropdown>
  );
}
